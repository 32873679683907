import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

import {
  Container,
  Row,
  Col
} from 'react-bootstrap';


import 'bootstrap/dist/css/bootstrap.min.css'

class Page404 extends React.Component {


  componentDidMount = () => {

  }

  render() {
    return (
      <>
        <Navbar />
        <div className="height-40" />
        <div style={{backgroundColor:"#d0e7ed"}}>
        <Container className="info-page">
          <Row className="align-items-center">
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}  >
                <div className="msg">
                <h1>403</h1>
                <h3>Não autorizado</h3>
                </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>

            </Col>
          </Row>
        </Container>
        </div>
        <div className="height-40" />
        <Footer />
        </>
    )
  }
}

export default Page404