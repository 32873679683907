import React from "react"
import { Container, Row,Col } from "react-bootstrap"



import "bootstrap/dist/css/bootstrap.min.css"
import "../css/multilogica-theme.scss"

const MainNavbar = () => {

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Blog</h1>
        </Col>
      </Row>
    </Container>
  )
}

export default MainNavbar
